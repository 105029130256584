import React from 'react'
import ContactMain from '../Components/Contact_main/ContactMain'

export default function Contact() {
  return (
    <div>
      <ContactMain/>
    </div>
  )
}
