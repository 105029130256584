import React from 'react'
import './NavMob.css'
import { Link } from 'react-router-dom'

export default function NavMob() {
  return (
    <div className='navmob_master'>
        <label className="popup">
  <input type="checkbox"/>
  <div className="burger" tabindex="0">
    <span></span>
    <span></span>
    <span></span>
  </div>
  <nav className="popup-window">
    <legend>Menu</legend>
    <ul> <Link to='/'>
      <li>
        <button> 
          <span>HOME</span>
        </button>
      </li>
      </Link>
      <Link to='/contact'>
      <li>
        <button>
          <span>CONTACT US</span>
        </button>
      </li>
      </Link>
      <Link to='/join'>
      <li>
        <button>
          <span>JOIN US</span>
        </button>
      </li>
      </Link>
    </ul>
  </nav>
</label>
    </div>
  )
}
