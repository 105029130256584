import React from 'react'
import './FooterMain.css'
import { Link } from 'react-router-dom'
import logo from './logo.png'

export default function FooterMain() {
  return (
    <div className='footer_master'>
       <div className="footer_main">
        <div className="footer_logo footer_section">
            <img src={logo} alt="" /> 
        </div>
        <div className="footer_menu footer_section">
          <ul>
            <Link to='/'><li>Home</li></Link>
            <Link to='/contact'><li>Contact Us</li></Link>
            <a target='_blank' href='https://t.me/+QBroqSf11gY4Y2Y1'><li>Join</li></a>
          </ul>
        </div>
       </div>
       <div className="footer_lic">
         <span>© POLARBEAR . ALL RIGHT RESERVED</span>
       </div>
    </div>
  )
}
