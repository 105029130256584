import React from 'react'
import FooterMain from '../Components/FooterMain/FooterMain'

export default function Footer() {
  return (
    <div>
        <FooterMain/>
    </div>
  )
}
