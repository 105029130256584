import React from 'react'
import WorkPage from '../Components/WorkPage/WorkPage'

export default function work() {
  return (
    <div>
      <WorkPage/>
    </div>
  )
}
