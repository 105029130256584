import React from 'react'
import './Fake.css'
import avatar1 from './Avatars/avatar.svg'
import avatar2 from './Avatars/avatar (1).svg'
import avatar3 from './Avatars/avatar (2).svg'
import avatar4 from './Avatars/avatar (3).svg'
import avatar5 from './Avatars/avatar (4).svg'
import avatar6 from './Avatars/avatar (5).svg'

export default function Fake() {
  return (
    <div className='review_master'>
        <div className="review_heading">
          <h4>Coding Brilliance Sparks Client Joy,Testimonials of Delighted Partners.</h4>
          <h3>Code's Brilliance, <span>Client's</span> Joy</h3>
        </div>
        <div className="review_items">
            <section1>
              <div className="review_item">
                <p>"The Polarbear crew is seriously talented! You guys were so patient and generous with your time, making sure we got top-notch service and creativity. Working with the Polarbear team was a fantastic experience! We'd absolutely recommend your business without a doubt. While there are plenty of web designers out there, none we've worked with so far match your skill set and moral character." </p>
                <div><img src={avatar1} alt="" /><div className="name">@Ethan Mitchell<br/>America</div></div>
              </div>
              <div className="review_item">
              <p>"Working with Polarbear is really enjoyable! Their expertise and designs make our application look stunning and perfect. If you're seeking a customized application, they'll guide you in the right direction, so I totally recommend them."</p>
              <div><img src={avatar5} alt="" /><div className="name">@Ahmed Farouk<br/>Kuwait</div></div>
              </div>
              <div className="review_item">
              <p>"I've had nothing but fantastic experiences with Polarbear. They're undoubtedly the most experienced and well-managed web design company I've ever worked with. In every project, their exceptional attention to detail, creativity, and insight shines through."</p>
              <div><img src={avatar3} alt="" /><div className="name">@James Coventry<br/>London</div></div>
              </div>
            </section1>
            <section2>
            <div className="review_item2">
                <p>"Our company's been collaborating with Polarbear since 2021. Polarbear has shown some good improvements along the way. Lately, they lent a hand with updating our application. Honestly, we're super happy with Polarbear's flawless support. The help they provided navigating us through the process has been priceless. They really know their stuff and pay great attention to details. Plus, they've taken the time to guide us through the ropes of the new administration interface. All in all, we're pretty pleased with Polarbear."</p>
                <div><img src={avatar4} alt="" /><div className="name">@Laila Abadi<br/>Kuwait</div></div>
              </div>
              <div className="review_item2">
              <p>"This business is top-notch; they're truly professional. Brought them on board for website design, and they did an AMAZING JOB. Their SEO work brought in new customers. Great advice on maintenance and changes. It's been the best financial investment. Once again, thank you."</p>
              <div><img src={avatar2} alt="" /><div className="name">@Shubham Kumar<br/>India</div></div>
              </div>
              <div className="review_item2">
              <p>"Finding the correct team is just as important as building a website. Fortunately, after a long search, I was able to locate Polarbear online. Their proficiency lies in creating, enhancing, and refining websites and applications. They are the ideal option because of their superb design sense and assistance."</p>
              <div><img src={avatar6} alt="" /><div className="name">@Mason Thompson<br/>America</div></div>
              </div>
            </section2>
        </div>
    </div>
  )
}
