import React from 'react'
import './MobileP.css'
import mobilei from './mobilei.png'
import website from './website.png'
import dotted from './dotted line.png'

export default function MobileP() {
  return (
    <div className='mobile_master'>
    <img className='mobile_stock' src={mobilei} alt="" />
    <img className='dotted_line1' src={dotted} alt="" />
      <div className="mobile_heading">
        <div className="mobile_h3">
         <h3>Join Us for digital Solutions</h3>
         <h3>Our Development Hub..</h3>
        </div>
        <div className="mobile_p">
          <p>Join us in embracing innovation! Become a part of our vibrant development hub for apps and websites. Get streamlined cooperation, modern technology, and customised approaches that improve business success and online visibility.</p>
        </div>
        <div className="mobile_button">
         <a target='_blank' href="https://t.me/+QBroqSf11gY4Y2Y1"><button>JOIN NOW</button></a>
        </div>
      </div>
    <img className='dotted_line2' src={dotted} alt="" />
    <img className='website_stock' src={website} alt="" />
  </div>
  )
}
