import React from 'react'
import Fake from '../Components/Fake/Fake'

export default function Review() {
  return (
    <div>
      <Fake/>
    </div>
  )
}
