import HomeMaster from './Pages/HomeMaster';
import TechStack from './Pages/TechStack';
import Work from './Pages/Work';
import Navbar from './Components/NavBar/Navbar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Industry from './Pages/Industry';
import Footer from './Pages/Footer';
import Mobile from './Pages/Mobile';
import Review from './Pages/Review';
import Contact from './Pages/Contact';
import GetIn from './Pages/GetIn';


function App() {
  return (
<>
<BrowserRouter>
<Routes>
  <Route path='/' element={<>
  <Navbar/>
  <HomeMaster/>
  <TechStack/>
  <Work/>
  <Mobile/>
  <Industry/>
  <Review/>
  <GetIn/>
  <Footer/>
  </>}/>
  <Route path='/contact' element={<>
    <Navbar/>
    <Contact/>
    <Footer/>
  </>}/>

  </Routes>
</BrowserRouter>
</>
  );
}

export default App;
