import React from 'react'
import GetInMain from '../Components/GetIn/GetInMain'

export default function GetIn() {
  return (
    <div>
      <GetInMain/>
    </div>
  )
}
