// import { useState } from 'react'
import React, {useState} from 'react'
import './ContactMain.css'
import axios from 'axios'
import { Navigate, useNavigate } from 'react-router-dom';
import { contactRoute } from '../../Utiles/APIRoutes';

export default function ContactMain() {

  const navigate = useNavigate()

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    option: '',
    description: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    try {
      const { username, email, option, desc } = formData
      const { response } = await axios.post(contactRoute,{
        username,
        email,
        option,
        desc,
      });
      console.log(response)
      alert('We will reach you soon ...')

    } catch (error) {
      console.error('Error:', error);
      alert('Internal server error. Please try again later.');
    }
  };


  return (
    <div className='contact_master'>
      <div className="contact_main">
        <h2>Get in Touch Now!..</h2>
          <form onSubmit={(event)=>handleSubmit(event)}>
        <div className="user_details">
           <div className="user_info">
            <h4>We like being on a first-name, but it also helps us get in touch with you</h4>
            <div className="user_input">
               <label>
                <span>What is your full name</span>
               <input  type="text" placeholder='name' name='username' onChange={e=>handleChange(e)}/>
               </label>
               <label>
                <span>What is your email address</span>
               <input onChange={e=>handleChange(e)} name='email' type="email" placeholder='user@gmail'/>
               </label>
               </div>
           </div>
           <div className="user_service">
              <span>What sort of creative work do you need help with ?</span>
              <div className="radio-button-container">
                    <div className="radio-button">
                    <input onChange={e=>handleChange(e)} value={'website'} type="radio" className="radio-button__input" id="radio1" name="website"/>
                    <label className="radio-button__label" for="radio1">
                      <span className="radio-button__custom"></span>
                      Website
                    </label>
                  </div>
                  <div className="radio-button">
                    <input onChange={e=>handleChange(e)} type="radio" className="radio-button__input" id="radio2" name="Application"/>
                    <label className="radio-button__label" for="radio2">
                      <span className="radio-button__custom"></span>
                      Application
                    </label>
                  </div>
                  <div className="radio-button">
                    <input onChange={e=>handleChange(e)} type="radio" className="radio-button__input" id="radio3" name="web-app"/>
                    <label className="radio-button__label" for="radio3">
                      <span className="radio-button__custom"></span>
                      Web + App
                    </label>
                  </div>
                  <div className="radio-button">
                    <input onChange={e=>handleChange(e)} type="radio" className="radio-button__input" id="radio4" name="other"/>
                    <label className="radio-button__label" for="radio4">
                      <span className="radio-button__custom"></span>
                      Other
                    </label>
                  </div>
                  </div>     
           </div>
        </div>
        <div className="user_des">
            <span>Tell us what you need help with, the purpose of this to describe your project</span>
            <textarea id="description" name="desc" onChange={e=>handleChange(e)} cols="20" rows="10" placeholder='Please write about your project'></textarea>
            <button type="submit">Submit</button>
        </div>
        </form>
      </div>
    </div>
  )
}
