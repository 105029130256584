import React from "react";
import styled, { keyframes, css } from "styled-components";
import './Techstack.css'
import react from '../Assets/react.png'
import html from '../Assets/html.png'
import csss from '../Assets/css.png'
import next from '../Assets/nextjs.png'
import mongo from '../Assets/mongodb.png'
import dj from '../Assets/node.png'
import swift from '../Assets/swift.png'
import js from '../Assets/javascript.png'
import aws from '../Assets/aws.png'
import linux from '../Assets/linux.png'
import native from '../Assets/native.png'

function TechStack() {
  const row1 = [
    react,
    html,
    csss,
    dj,
    next,
    mongo
  ];

  const row2 = [
     swift,
     js,
     aws,
     dj,
     linux,
     native  
  ];

  return (
    <div style={{
      display:"flex",
      alignItems: "center",
      justifyContent: "center",
      width: '100%'
    }}>
    <AppContainer className="AppContainer_master">
      <Wrapper>
        <Text className="Text_heading">Optimized Dev Environments</Text>
        <Note className="Note_master">Optimizing performance by fine-tuning development settings to maximize efficiency.</Note>
        <Marquee className="Master_img_group">
          <MarqueeGroup>
            {row1.map((el) => (
              <ImageGroup className="image_box">
                <Image className="image_main" src={el} />
              </ImageGroup>
            ))}
          </MarqueeGroup>
          <MarqueeGroup >
            {row1.map((el) => (
              <ImageGroup className="image_box">
                <Image className="image_main" src={el} />
              </ImageGroup>
            ))}
          </MarqueeGroup>
        </Marquee>
        <Marquee className="Master_img_group margin-2">
          <MarqueeGroup2>
            {row2.map((el) => (
              <ImageGroup className="image_box">
                <Image className="image_main" src={el} />
              </ImageGroup>
            ))}
          </MarqueeGroup2>
          <MarqueeGroup2>
            {row2.map((el) => (
              <ImageGroup className="image_box">
                <Image className="image_main" src={el} />
              </ImageGroup>
            ))}
          </MarqueeGroup2>
        </Marquee>
      </Wrapper>
    </AppContainer>
    </div>
  );
}

export default TechStack;

const AppContainer = styled.div`
  margin-top: 50px;
  width: 100%;
  max-width:1440px;
  height: max-contant;
  color: #000000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;


const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Text = styled.div`
  font-size: 4.5vw;
  letter-spacing: -2px;
  font-weight: 600;
  color: #111112;
`;

const Note = styled.div`
  font-size: 1.25vw;
  font-weight: 600;
  margin-bottom: 40px;
  color: #6A727B;
`;

const Marquee = styled.div`
  display: flex;
  width: 80%;
  max-width: 1440px;
  overflow: hidden;
  user-select: none;

  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );
`;

const scrollX = keyframes`
  from {
    left: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  animation: ${scrollX} 30s linear infinite;
`;

const MarqueeGroup = styled.div`
  ${common}
`;
const MarqueeGroup2 = styled.div`
  ${common}
  animation-direction: reverse;
  animation-delay: -3s;
`;

const ImageGroup = styled.div`
overflow:hidden;
  display: grid;
  place-items: center;
  width: clamp(10rem, 1rem + 40vmin, 30rem);
  padding: calc(clamp(10rem, 1rem + 30vmin, 30rem) / 10);
`;

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  border-radius: 0.5rem;
  aspect-ratio: 16/9;
  padding: 5px 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;
