import React, { useState } from 'react'
import './GetInMain.css'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { consultRouter } from '../../Utiles/APIRoutes';

export default function GetInMain() {

 const navigate = useNavigate();

  const [value, setValue] = useState({
    email:''
  });

  const handleSubmit = async (e)=>{
    e.preventDefault();
  if(handleValidation()){
  const { email } = value;
  const {data} = await axios.post(consultRouter, {
    email,
  });
  console.log(data);

    if(data.status === false){
      alert('you are already registerd')
      return false;
    }
    if(data.status === true){
         alert('Contact you soon !!');
     }
     navigate('/contact')
  }
  }

  const handleValidation = ()=>{
    const {email} = value;
    console.log(value)
        if(email === ''){
          alert('complete the input field !!');
          return false;
        }
        return true;
  }

 const handleChange = (e)=>{
    setValue({...value,[e.target.name]: e.target.value})
 }

  
    
  return (
    <div className='getin_master'>
       <div className="getin_main">
           <div className="getin_content">
              <h4>Let's Build Together</h4>
              <h5>Let's connect today to ignite innovation and shape your digital future.</h5>
              <Link to="/contact"><button className=" button-ani learn-more">
                  <span className="circle" aria-hidden="true">
                  <span className="icon arrow"></span>
                  </span>
                  <span className="button-text">Learn More</span>
                </button>
              </Link>
           </div>
           <div className="getin_join">
           <form className="form" onSubmit={(event)=>handleSubmit(event)}>
  <span className="title2">Connect with us if your old website needs consultation.</span>
  <p className="description">Give your website a makeover. Get in touch for professional advice on modernising your outdated web presence.</p>
  <div>
    <input placeholder="Enter your email" type="email" name="email" id="email-address" onChange={e=>handleChange(e)}/>
    <button type="submit">Connect Now</button>
  </div>
</form>
           </div>
       </div>
    </div>
  )
}
