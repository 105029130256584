import React from 'react'
import IndustryMain from '../Components/Indusrtry/IndustryMain'

export default function Industry() {
  return (
    <div>
      <IndustryMain/>
    </div>
  )
}
