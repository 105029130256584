import React from 'react'
import Home from '../Components/Home/Home'


export default function HomeMaster() {
  return (
    <div>
  <Home/>
    </div>
  )
}
