import React from 'react'
import './Navbar.css'
import logo from './logo.png'
import { Link } from 'react-router-dom'
import NavMob from '../NavbarMob/NavMob'

export default function Navbar() {
  return (
    <div className='navmaster'>
        <div className="navbar_main">
          <div className="nav_logo">
               <img src= {logo} alt="" />
          </div>
          <div className="nav_menu">
            <ul>
              <Link to='/'><li>HOME</li></Link>
              <Link to='/contact'><li>CONTACT US</li></Link>
              <a target='_blank' href='https://t.me/+QBroqSf11gY4Y2Y1'><li>JOIN</li></a>
            </ul>
          </div>
            <NavMob/>
        </div>
    </div>
  )
}
